import React from "react";
import "./App.css";
import "./jquery.lazylinepainter-1.7.0_modified";
import "./painter";
import FogComponent from "./components/FogComponent";

function App() {

  return (
    <FogComponent>
    <div className="App">
        <div id="content_lazy">
          <div id="lazy"></div>
        </div>
    </div>
    </FogComponent>
  );
}

export default App;
